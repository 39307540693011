<template>
  <layout name="HomeLayout">
    <div class="Home">
      <section class="uk-section uk-padding-remove">
        <div class="uk-container">

          <div v-if="error!==null" class="uk-alert-warning" uk-alert>
            <p v-html="error" class="uk-text-center"></p>
          </div>

          <vue-good-wizard
            ref="wizard"
            :steps="steps"
            :onNext="nextClicked"
            :onBack="backClicked">
            <div slot="page1">
              <div>
                <header class="uk-text-center">
                  <h1 class="uk-heading-primary">Price</h1>
                  <p
                    class="uk-width-3-5 uk-margin-auto"
                  >Our Product is the easiest way for teams to track their works progress. Our advance plans give you
                    more
                    tools to get the job done.</p>
                </header>
                <div
                  class="uk-grid uk-grid-small uk-child-width-1-3@m uk-margin-medium-top uk-grid-match"
                  data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div > .uk-card; delay: 200"
                  data-uk-grid
                >
                  <!-- price -->
                  <div>
                    <div :class="{ selecteditem: vps == 1 }"
                         class="uk-card uk-card-default uk-card-hover uk-flex uk-flex-column"
                         data-uk-scrollspy-class="uk-animation-slide-left-small"
                    >
                      <div class="uk-card-header uk-text-center">
                        <h4 class="uk-text-bold">PERSONAL</h4>
                      </div>
                      <div class="uk-card-body uk-flex-1">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                        <span style="font-size: 4rem; font-weight: 200; line-height: 1em">
                          <span style="font-size: 0.5em">$</span>9
                          <small>.99</small>
                        </span>
                        </div>
                        <div
                          class="uk-text-small uk-text-center uk-text-muted"
                        >Per member billed annually
                        </div>
                        <ul>
                          <li>2 users included</li>
                          <li>1 GB of storage</li>
                          <li>A beer jar</li>
                        </ul>
                      </div>
                      <div class="uk-card-footer">
                        <button
                          @click="selectvps(1)"
                          href="#"

                          class="uk-button uk-button-primary uk-width-1-1"
                        >SELECT
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- /price -->
                  <!-- price -->
                  <div>
                    <div :class="{ selecteditem: vps == 2 }"
                         class="uk-card uk-card-default uk-card-hover uk-flex uk-flex-column">
                      <div class="uk-card-header uk-text-center">
                        <h4 class="uk-text-bold">PROFESSIONAL</h4>
                      </div>
                      <div class="uk-card-body uk-flex-1">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                        <span style="font-size: 4rem; font-weight: 200; line-height: 1em">
                          <span style="font-size: 0.5em">$</span>89
                          <small>.99</small>
                        </span>
                        </div>
                        <div
                          class="uk-text-small uk-text-center uk-text-muted"
                        >Per member billed annually
                        </div>
                        <ul>
                          <li>10 users included</li>
                          <li>5 GB of storage</li>
                          <li>Email support</li>
                          <li>A beer jar with beer inside!</li>
                        </ul>
                      </div>
                      <div class="uk-card-footer">
                        <button
                          @click="selectvps(2)"
                          href="#"

                          class="uk-button uk-button-primary uk-width-1-1"
                        >SELECT
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- /price -->
                  <!-- price -->
                  <div>
                    <div :class="{ selecteditem: vps == 3 }"
                         class="uk-card uk-card-default uk-flex uk-card-hover uk-flex-column"
                         data-uk-scrollspy-class="uk-animation-slide-right-small"
                    >
                      <div class="uk-card-header uk-text-center">
                        <h4 class="uk-text-bold">CORPORATE</h4>
                      </div>
                      <div class="uk-card-body uk-flex-1">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                        <span style="font-size: 4rem; font-weight: 200; line-height: 1em">
                          <span style="font-size: 0.5em">$</span>299
                        </span>
                        </div>
                        <div
                          class="uk-text-small uk-text-center uk-text-muted"
                        >Per member billed annually
                        </div>
                        <ul>
                          <li>Unlimited users</li>
                          <li>Unlimited storage</li>
                          <li>Email support</li>
                          <li>Help center access</li>
                        </ul>
                      </div>
                      <div class="uk-card-footer">
                        <button
                          @click="selectvps(3)"
                          href="#"

                          class="uk-button uk-button-primary uk-width-1-1"
                        >SELECT
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- /price -->
                </div>
              </div>
            </div>
            <div slot="page2">

              <div>
                <form method="post" class="uk-form-stacked uk-margin-medium-top">
                  <div class="uk-margin">
                    <label class="uk-form-label" for="country">Country</label>
                    <div class="uk-form-controls">

                      <select :class="{ 'uk-form-danger': validcountry }" @change="onChangeValue($event)"
                              v-if="countries" v-model="country" class="uk-select"
                              id="country">

                        <option v-for="(item, index) in countries" :key="index" :value="item.id"
                        >{{item.name}}
                        </option>

                      </select>
                    </div>
                  </div>

                  <div v-if="states" class="uk-margin">
                    <label class="uk-form-label" for="state">State</label>
                    <div class="uk-form-controls">
                      <select :class="{ 'uk-form-danger': validstate }" @change="onChangeState($event)" v-model="state"
                              class="uk-select"
                              id="state">
                        <option value="">select item ...</option>
                        <option v-for="(item, index) in states" :key="index" :value="item.id">{{item.name}}</option>

                      </select>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label" for="address">Organization Address</label>
                    <div class="uk-form-controls">
                      <input v-model="address" required class="uk-input" id="address" type="text"/>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label" for="zipcode">Zip code</label>
                    <div class="uk-form-controls">
                      <input v-model="zipcode" required class="uk-input" id="zipcode" type="text"/>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label" for="name">Organization Name</label>
                    <div class="uk-form-controls">
                      <input v-model="organizname" :class="{ 'uk-form-danger': validname }" class="uk-input" id="name"
                             type="text"/>
                    </div>
                  </div>

                  <div class="uk-margin">
                    <label class="uk-form-label" for="phone">Phone</label>
                    <div class="uk-form-controls">
                      <input v-model="phone" :class="{ 'uk-form-danger': validphone }" required class="uk-input"
                             id="phone" type="phone"/>
                    </div>
                  </div>

                  <div class="uk-margin">
                    <label class="uk-form-label" for="email">Email</label>
                    <div class="uk-form-controls">
                      <input v-model="email" :class="{ 'uk-form-danger': validemail }" required class="uk-input"
                             id="email" type="email"/>
                    </div>
                  </div>

                  <div class="uk-margin">
                    <label class="uk-form-label">Organization type</label>
                    <div class="uk-form-controls">
                      <select required v-model="organiztype" class="uk-select" id="organiztype">
                        <option value="university">University</option>
                        <option value="company">Company</option>
                        <option value="hospital">Hospital</option>
                        <option value="school">School</option>
                        <option value="group">Group</option>
                        <option value="academy">Academy</option>
                        <option value="etc">ETC</option>
                      </select>
                    </div>
                  </div>
                  <div class="uk-margin">{{$t('relayServer')}}</div>
                  <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label>
                      <input v-model="isrelay" value="1" class="uk-radio" type="radio" name="radio2" checked/> {{$t('yes')}}
                    </label>
                    <label>
                      <input v-model="isrelay" value="0" class="uk-radio" type="radio" name="radio2"/> {{$t('no')}}
                    </label>
                  </div>

                </form>
              </div>
            </div>
            <div slot="page3">

              <p> Before a user can create an account on UBT CLOUD and use any of the services provided by AWS,
                the user must click a box that indicates that the linked terms of Amazon AWS have been read
                and
                are agreed to:
                You can use the clickwrap to not only obtain initial consent to your Terms and Conditions
                agreement (or any other legal agreement that you present to users) but also when your
                agreements
                change and you want to get consent over the new and updated agreements.</p>
            </div>
            <div slot="page4">
              <h4>Step 4</h4>
              <p>This is step 4</p>
            </div>
          </vue-good-wizard>


        </div>

        <div id="modal-success" uk-modal>
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default"  uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Thank you</h2>
            </div>
            <div class="uk-modal-body">

              <p v-html="message"></p>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <router-link
                tag="a"
                class="uk-button uk-button-primary uk-modal-close"
                :to="{
              name: 'home'
            }"
              >Okey
              </router-link>

            </div>
          </div>
        </div>
      </section>
    </div>
  </layout>
</template>

<script>
  import UserService from '@/services/UserService'
  import Layout from '../layouts/Layout'
  import GoodWizard from '@/components/GlobViews/Wizard'
  import UIkit from 'uikit'
  import _ from 'lodash'

  export default {
    name: 'Home',
    components: {
      Layout,
      'vue-good-wizard': GoodWizard,
    },

    data() {
      return {
        message: '',
        email: '',
        organizname: null,
        phone: '',
        isrelay: 1,
        country: null,
        state: null,
        error: null,
        countries: null,
        zipcode: '',
        address: '',
        organiztype: '',
        states: null,
        vps: 0,
        steps: [
          {
            label: 'VIRTUAL TMS',
            slot: 'page1',
          },
          {
            label: 'CREATE ACCOUNT',
            slot: 'page2',
          },
          {
            label: 'AGREEMENT',
            slot: 'page3',
            options: {
              nextDisabled: false,
              nextStepLabel: 'Daraah',
              finalStepLabel: 'I agree'
            }
          }
        ],
        validname: false,
        validemail: false,
        validphone: false,
        validcountry: false,
        validstate: false

      }
    },
    watch: {
      organizname: _.debounce(async function () {

        if (this.organizname !== '') {

          this.validname = false
        } else {
          this.validname = true
        }
      }, 500),
      email: _.debounce(async function () {

        if (this.email !== '') {

          this.validemail = false
        } else {
          this.validemail = true
        }
      }, 500),
      phone: _.debounce(async function () {

        if (this.phone !== '') {

          this.validphone = false
        } else {
          this.validphone = true
        }
      }, 500)
    }
    ,
    created() {
      this.getcountries()
    },
    methods: {
      nextClicked(currentPage) {
        // const _this = this;
        // _this.$refs.wizard.goNext(true);
        if (currentPage === 1) {

          if (!this.organizname) {
            this.validname = true
            return false;
          }
          if (!this.email) {
            this.validemail = true
            return false;
          }

          if (!this.phone) {
            this.validphone = true
            return false;
          }

          if (!this.country) {
            this.validcountry = true
            return false;
          }

          if (!this.state) {
            this.validstate = true
            return false;
          }
        }

        if (currentPage === 2) {
          // alert("save")
          this.register()
        }
        return true; //return false if you want to prevent moving to next page
      },
      backClicked() {
        return true; //return false if you want to prevent moving to previous page
      },

      selectvps: function (value) {
        this.vps = value
      },
      onChangeValue: function () {

        this.getStates(this.country)
        if (this.coutry) {
          this.validcountry = false

        }


      },

      onChangeState: function () {

        if (this.state) {
          this.validstate = false
        }


      },
      async getcountries() {
        try {
          const response = await UserService.getCountries()

          this.countries = response.data

        } catch (error) {
          this.error = error.response.data.error
        }
      },


      async getStates(countryId) {
        if (countryId !== 0) {
          try {
            await UserService.getStates(countryId).then(res => {
              const {data} = res
              this.states = data

            })
          } catch (error) {
            this.error = error.response.data.error
          }
        }
      },

      async register() {
        try {
          const response = await UserService.requestOrganization({
            name: this.organizname,
            image: '',
            vpstype: this.vpstype,
            phone: this.phone,
            email: this.email,
            isrelay: this.isrelay,
            organizType: this.organizType,
            zipcode: this.zipcode,
            state: this.state,
            country: this.country,
            address: this.address
          })

          if (response.data.status === 200) {

            this.message = response.data.message
            if (this.message) {
              // UIkit.modal('#modal-success').show()

              UIkit.modal.alert(this.message).then(function () {
                // console.log('Alert closed.')
              });

              // this.$router.push({name: 'home'})

            }
          }

          // this.$router.push({
          //   name: 'login'
          // })
        } catch (error) {
          console.log(error)
        }
      },
      scrollToTop() {
        window.scrollTo(0, 0)
      }
    }
  }
</script>

<style scoped>
  .selecteditem {
    border: 1px solid green;
  }
</style>
